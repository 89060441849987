import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from 'store';
import { saveToLocalFile } from 'lib';

import Button from 'components/Button/Button';

import './ChangeProtocolPhase.scss';

type Props = PropsFromRedux & {
    onBackClick: () => void;
}

const ChangeProtocolPhase: React.FC<Props> = ({ onBackClick, deviceConnectionMethod }) => {

    const onBackClickEvent = () => {
        onBackClick();
    }

    const onClickHTTPS = () => {
        // eslint-disable-next-line no-restricted-globals
        location.replace(`https://scratchlinkcodes.au/#/coding/deviceConnection`);
    }

    const onClickHTTP = () => {
        // eslint-disable-next-line no-restricted-globals
        location.replace(`http://scratchlinkcodeswifi.au/#/coding/deviceConnection`);   
    }

    const onClickSave = () => {
        saveToLocalFile();
    }

    return (
        <>
            {
                deviceConnectionMethod === 'serial' && (
                    <div className="c-changeProtocolPhase">
                        <p>Are you sure about using the USB Cable (serial)?</p>
                        <p>Save any projects now before switching or you will lose any unsaved work.</p>
                        <div className="c-changeProtocolPhase__buttons">
                            <Button type="danger" addStyles={['square']} onClick={onBackClickEvent}>Back</Button>
                            <Button type="success" addStyles={['square']} onClick={onClickSave}>Save</Button>
                            <Button type="info" addStyles={['square']} onClick={onClickHTTPS}>Go To USB Cable (Serial)</Button>
                        </div>
                    </div>
                )
            }
            {
                deviceConnectionMethod === 'modem' && (
                    <div className="c-changeProtocolPhase">
                        <p>Are you sure about using the USB Modem (serial)?</p>
                        <p>Save any projects now before switching or you will lose any unsaved work.</p>
                        <div className="c-changeProtocolPhase__buttons">
                            <Button type="danger" addStyles={['square']} onClick={onBackClickEvent}>Back</Button>
                            <Button type="success" addStyles={['square']} onClick={onClickSave}>Save</Button>
                            <Button type="info" addStyles={['square']} onClick={onClickHTTPS}>Go To USB Modem (Serial)</Button>
                        </div>
                    </div>
                )
            }
            {
                deviceConnectionMethod === 'ap' && (
                    <div className="c-changeProtocolPhase">
                        <p>Are you sure about using the eBot Wifi Hotspot (Wifi AP)?</p>
                        <p>Save any projects now before switching or you will lose any unsaved work.</p>
                        <div className="c-changeProtocolPhase__buttons">
                            <Button type="danger" addStyles={['square']} onClick={onBackClickEvent}>Back</Button>
                            <Button type="success" addStyles={['square']} onClick={onClickSave}>Save</Button>
                            <Button type="info" addStyles={['square']} onClick={onClickHTTP}>Go To eBot Wifi Hotspot (Wifi AP)</Button>
                        </div>
                    </div>
                )
            }
            {
                deviceConnectionMethod === 'ip' && (
                    <div className="c-changeProtocolPhase">
                        <p>Are you sure about using the Wifi Network?</p>
                        <p>Save any projects now before switching or you will lose any unsaved work.</p>
                        <div className="c-changeProtocolPhase__buttons">
                            <Button type="danger" addStyles={['square']} onClick={onBackClickEvent}>Back</Button>
                            <Button type="success" addStyles={['square']} onClick={onClickSave}>Save</Button>
                            <Button type="info" addStyles={['square']} onClick={onClickHTTP}>Go To Wifi Network</Button>
                        </div>
                    </div>
                )
            }
        </>
    )
}

const mapStateToProps = (state: IRootState) => {
    return {
        deviceConnectionMethod: state.project.deviceConnectionMethod
    }
}

const mapDispatchToProps = {
    
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ChangeProtocolPhase);