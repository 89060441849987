import React, { } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState, projectSetDeviceConnectionMethod } from 'store';
import { DeviceConnectionManager } from 'lib/DeviceConnection';
import isDevelopment from 'utils/isDevelopment';

import Button from 'components/Button/Button';

import './ConnectPhase.scss';

type Props = PropsFromRedux & {
    onClickDirectIp: () => void;
    onClickChangeProtocol: () => void;
    onSerialError: () => void;
}

const ConnectPhase: React.FC<Props> = ({ onClickDirectIp, onClickChangeProtocol, onSerialError, projectSetDeviceConnectionMethod }) => {
    const checkWrongSite = (site: 'scratchlinkcodeswifi.au' | 'scratchlinkcodes.au') => {
        if(window.location.hostname === site && !isDevelopment()) {
            onClickChangeProtocol();
            return true;
        }
        return false;
    }

    const checkSerialError = () => {
        return !navigator.serial || !navigator.serial.requestPort || typeof navigator.serial.requestPort !== 'function';
    }

    const onClickScratchLinkAp = () => {
        projectSetDeviceConnectionMethod('ap');
        if(checkWrongSite('scratchlinkcodes.au')) return;
        DeviceConnectionManager.connect();
    }

    const onClickDirectIpEvent = () => {
        projectSetDeviceConnectionMethod('ip');
        if(checkWrongSite('scratchlinkcodes.au')) return;
        onClickDirectIp();
    }

    const onClickSerial = async () => {
        projectSetDeviceConnectionMethod('serial');
        if(checkWrongSite('scratchlinkcodeswifi.au')) return;
        if(checkSerialError()) return onSerialError();
        DeviceConnectionManager.connect();
    }

    const onClickModem = async () => {
        projectSetDeviceConnectionMethod('modem');
        if(checkWrongSite('scratchlinkcodeswifi.au')) return;
        if(checkSerialError()) return onSerialError();
        DeviceConnectionManager.connect();
    }

    return (
        <div className="c-connectPhase">
            <Button type="info" addStyles={['square', 'full width']} onClick={onClickScratchLinkAp}>eBot Wifi HotSpot (http site)</Button>
            <br />
            <Button type="info" addStyles={['square', 'full width']} onClick={onClickDirectIpEvent}>WIFI Network IP Connect (http site)</Button>
            <br />
            <Button type="info" addStyles={['square', 'full width']} onClick={onClickSerial}>USB Cable (https site)</Button>
            <br />
            <Button type="info" addStyles={['square', 'full width']} onClick={onClickModem}>USB Modem (https site)</Button>
        </div>
    )
}

const mapStateToProps = (state: IRootState): StateProps => {
    return {
    }
}

const mapDispatchToProps = {
    projectSetDeviceConnectionMethod
}

interface StateProps {
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConnectPhase);